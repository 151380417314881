"use client";

import { track } from "@vercel/analytics";
import { endOfToday, format, parse, subMonths } from "date-fns";
import { sortBy } from "lodash";
import Link from "next/link";
import { useEffect, useState } from "react";

import type { NewsStoryblok } from "@/component-types-sb";
import { getNews } from "@/lib/storyblok/getNews";

type NewsTeaserProps = {
  campsiteId?: string;
  maxNews?: number;
  title?: string;
};

const NewsTeaser = ({
  campsiteId,
  maxNews = 3,
  title = "Nachrichten",
}: NewsTeaserProps) => {
  const [news, setNews] = useState<NewsStoryblok[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getNews({
        filter_query: {
          date: {
            gt_date: format(subMonths(new Date(), 6), "yyyy-MM-dd HH:mm"),
            lt_date: format(endOfToday(), "yyyy-MM-dd HH:mm"),
          },
          campsites: { in_array: campsiteId || null },
        },
      });
      const sortedByDate = sortBy(data, ["content.date"]);
      setNews(sortedByDate);
      setIsLoading(false);
    };
    fetchData();
  }, [campsiteId, maxNews]);

  return (
    <div className="flex flex-col gap-2 mt-4">
      {isLoading && <Skeleton number={maxNews} />}

      {!isLoading && news.length > 0 && (
        <>
          <Link href="/veranstaltungen">
            <span className="font-semibold text-brand-primary100">{title}</span>
          </Link>
          <ul className="w-full text-sm">
            {news?.slice(0, maxNews).map((blok) => (
              <li key={blok.id} className="px-2 py-2 border-b border-gray-200">
                <Link
                  href={blok.full_slug}
                  onClick={() => track("News click")}
                  className="flex gap-2"
                >
                  <time dateTime={blok.content?.date}>
                    {format(
                      parse(blok.content?.date, "yyyy-MM-dd HH:mm", new Date()),
                      "dd.MM."
                    )}
                  </time>
                  {blok.name}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const Skeleton = ({ number = 1 }) => (
  <>
    <div
      role="status"
      className="w-8/12 mb-1 h-6 bg-gray-200 animate-pulse"
    ></div>
    {Array(number)
      .fill(0)
      .map((_, index) => (
        <div
          key={index}
          className="w-12/12 my-1 h-4 bg-gray-200 animate-pulse"
        ></div>
      ))}
  </>
);

export { NewsTeaser };

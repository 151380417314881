import type { MenuButtonStoryblok } from "@/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import { clsx } from "clsx";
import Link from "next/link";
import { useParams } from "next/navigation";
import type { HTMLAttributes, MouseEvent } from "react";
import { ReactNode } from "react";

import { Button } from "@/components/layout/Button";

type MenuButtonProps = HTMLAttributes<HTMLElement> & {
  blok: MenuButtonStoryblok;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

const MenuButton = ({ blok, children, onClick, ...rest }: MenuButtonProps) => {
  const params = useParams();
  const lang = params.lang as string;
  const url =
    lang === "en" ? blok.link?.cached_url : `/${blok.link?.cached_url}`;

  return (
    <Link
      href={url || "#"}
      onClick={onClick}
      className={clsx("p-2.5", rest.className)}
      {...storyblokEditable(blok)}
    >
      <Button
        colorScheme="secondary"
        className="transition-transform duration-300 h-6 text-xs lg:h-auto lg:text-normal"
      >
        {blok.name}
      </Button>
    </Link>
  );
};

export default MenuButton;

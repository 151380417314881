import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { track } from "@vercel/analytics";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useParams } from "next/navigation";

import { Fragment, Suspense, useContext, useEffect, useState } from "react";

import type { CampsiteStoryblok } from "@/component-types-sb";
import type { IconName } from "@/components/icons/Icon";
import { Stars } from "@/components/icons/Stars";
import { Button } from "@/components/layout/Button";
import { EventTeaser } from "@/components/layout/EventTeaser";
import { Modal } from "@/components/layout/Modal";
import { NewsTeaser } from "@/components/layout/NewsTeaser";
import { OfferTeaser } from "@/components/layout/OfferTeaser";
import { Spinner } from "@/components/layout/Spinner";
import { H1, H3, H4, Tag } from "@/components/typography";
import { CampsiteContext } from "@/lib/context/CampsiteContext";
import type { Area, ColorScheme, Dictionary, Language } from "@/lib/types";
import { formatPrice } from "@/lib/utils/format";

const IFrame = dynamic(
  () => import("../../layout/IFrame").then((module) => module.IFrame),
  { ssr: false }
);

const Icon = dynamic(() =>
  import("@/components/icons/Icon").then((module) => module.Icon)
);

type CampsiteProps = {
  blok: CampsiteStoryblok;
  uuid: string;
  dict?: Dictionary;
};

const tagColor: Record<Area, ColorScheme> = {
  Norden: "teal",
  Süden: "beige",
  Mitte: "brown",
  Osten: "brown",
};

export const mapAreaNames: Record<Language, Record<Area, string>> = {
  de: { Norden: "Norden", Süden: "Süden", Mitte: "Mitte", Osten: "Osten" },
  en: { Norden: "North", Süden: "South", Mitte: "Center", Osten: "East" },
};

const Campsite = ({ blok, uuid, dict }: CampsiteProps) => {
  const { lang } = useParams<{ lang: Language }>();
  const tagColorScheme = tagColor[blok.area as Area];
  const area = mapAreaNames[lang][blok.area as Area];

  const [isLoading, setLoading] = useState(true);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const { setCode, setId } = useContext(CampsiteContext);

  useEffect(() => {
    setCode(blok.code || "");
    setId(uuid || "");
  }, [blok.code, setCode, uuid, setId]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="bg-white relative max-w-6xl mx-auto grid grid-cols-3 gap-8 p-4">
        <div className="col-span-3 md:col-span-2 md:p-8">
          <div className="flex gap-1 mb-2">
            {blok.services?.map((name) => (
              <Icon key={name} name={name as IconName} className="w-6 h-6 " />
            ))}
          </div>

          <H4 fontSize="2xl">{blok.title}</H4>

          <Link href="/themen/klassifizierung" className="flex mt-2">
            <H1 colorScheme="primary">{blok.name}</H1>
            <Stars count={blok.stars} />
          </Link>

          <H3 as="h2">{blok.lead}</H3>

          <div className="flex gap-2 flex-wrap">
            <Tag colorScheme={tagColorScheme}>{area}</Tag>
            <Tag colorScheme={tagColorScheme}>{blok.state}</Tag>
            {blok.categories?.map((category: any) => (
              <Link
                key={category.uuid}
                href={["/", category.full_slug].join("")}
              >
                <Tag colorScheme={tagColorScheme}>{category.content?.name}</Tag>
              </Link>
            ))}
            {blok.highlights?.map((highlight: string) => (
              <Tag key={highlight} colorScheme={tagColorScheme}>
                {highlight}
              </Tag>
            ))}
          </div>

          <p className="py-4">{blok.description}</p>

          {blok.intro?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
        <aside className="col-span-3 md:col-span-1 md:p-8">
          <div className="bg-brand-primary100 text-white p-4">
            {blok.price && (
              <Fragment>
                <div className="text-sm py-2">{dict?.priceFrom}</div>
                <div className="text-3xl font-extrabold">
                  {formatPrice(blok.price)}
                </div>
                <div className="text-sm py-2">{blok.price_text}</div>
              </Fragment>
            )}

            {blok.code ? (
              <Fragment>
                <Link
                  href="/buchen"
                  onClick={(e) => {
                    e.preventDefault();
                    track("Booking click", { name: blok.name });
                    setBookingModalOpen(true);
                  }}
                >
                  <Button colorScheme="outline" className="my-4">
                    {blok.booking_label || "Buchen"}
                  </Button>
                </Link>

                <Modal
                  className="max-w-[1300px]"
                  open={bookingModalOpen}
                  onClose={() => setBookingModalOpen(false)}
                >
                  <Suspense fallback={<Spinner />}>
                    <IFrame className="mb-48" code={blok.code} lang={lang} />
                  </Suspense>
                </Modal>
              </Fragment>
            ) : blok.booking_link ? (
              <Link
                href={blok.booking_link?.url}
                target={blok.booking_link.target || "_self"}
                onClick={() => {
                  track("Booking click", { name: blok.name });
                }}
              >
                <Button colorScheme="outline" className="my-4">
                  {blok.booking_label || dict?.booking}
                </Button>
              </Link>
            ) : (
              <a href={`mailto:${blok.email}?subject=Buchungsanfrage`}>
                <Button colorScheme="outline" className="my-4">
                  {blok.booking_label || dict?.booking}
                </Button>
              </a>
            )}
          </div>

          {blok.map?.filename && (
            <Fragment>
              <Link
                href={blok.map.filename}
                type="image/jpeg"
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  setMapModalOpen(true);
                }}
              >
                <Button arrow colorScheme="primary" className="my-4">
                  {blok.map_label || dict?.map}
                </Button>
              </Link>

              <Modal
                className="max-w-6xl"
                open={mapModalOpen}
                onClose={() => setMapModalOpen(false)}
              >
                <Fragment>
                  {isLoading && <Spinner />}
                  <Image
                    src={blok.map.filename || ""}
                    alt={blok.map_label || dict?.map || ""}
                    width={7000}
                    height={4950}
                    onLoad={() => {
                      setLoading(false);
                    }}
                  />
                </Fragment>
              </Modal>
            </Fragment>
          )}

          <EventTeaser campsiteId={uuid} title={dict?.events.upcoming} />
          <OfferTeaser campsiteId={uuid} title={dict?.offers} />
          <NewsTeaser campsiteId={uuid} title={dict?.news} />
        </aside>
      </section>

      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </article>
  );
};

export default Campsite;

"use client";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useParams, usePathname } from "next/navigation";

import { getSlugFromPath, isLocalized } from "@/lib/dictionaries/utils";

const Icon = dynamic(() =>
  import("@/components/icons/Icon").then((module) => module.Icon)
);

const LanguageSwitch = () => {
  const { lang } = useParams();
  const pathname = usePathname();

  const slug = getSlugFromPath(pathname);

  if (!isLocalized(pathname)) {
    return null;
  }

  return (
    <div className="pr-3">
      {lang === "de" && (
        <Link
          href={`/en/${slug}`}
          className="flex items-center gap-2 text-sm font-semibold text-gray-900 hover:text-brand-primary100"
        >
          <Icon name="uk" title="English" className="h-3 w-auto" />
          <span className="hidden md:flex">English</span>
        </Link>
      )}
      {lang === "en" && (
        <Link
          href={`/${slug}`}
          className="flex items-center gap-2 text-sm font-semibold text-gray-900 hover:text-brand-primary100"
        >
          <Icon name="de" title="Deutsch" className="h-3 w-auto" />
          <span className="hidden md:flex">Deutsch</span>
        </Link>
      )}
    </div>
  );
};

export { LanguageSwitch };

export const slugs = [
  "",
  "campingparks",
  "bad-duerkheim",
  "bad-kissingen",
  "bernkastel-kues",
  "dorum-nordsee",
  "eckwarderhoerne-nordsee",
  "elbtalaue-bleckede",
  "eschwege",
  "essen-werden",
  "frickenhausen",
  "hamburg",
  "haren",
  "hennesee-meschede",
  "hennesee-meschede",
  "hotel-k1-nohra",
  "huenfeld-praforst",
  "koblenz",
  "lackenhaeuser",
  "leipzig",
  "luebben-spreewald",
  "meppen",
  "mosel-burgen",
  "nassau",
  "nassau",
  "nuernberg",
  "oyten-bremen",
  "ruegen",
  "schloss-langenau",
  "spieka-wattenmeer",
  "viechtach",
  "walkenried",
  "wingst",
];

export const getSlugFromPath = (pathname?: string) => {
  if (!pathname) {
    return "";
  }
  // Remove existing locale from path (if any)
  const segments = pathname.split("/").filter(Boolean);
  if (["en", "de"].includes(segments[0])) {
    segments.shift();
  }
  return segments.join("/");
};

export const isLocalized = (pathname?: string): boolean => {
  if (!pathname) {
    return false;
  }
  const slug = getSlugFromPath(pathname);
  return slugs.includes(slug);
};

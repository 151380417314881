import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/MusicPlayer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/Navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/storyblok/StoryblokProvider.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"weight\":[\"200\",\"400\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"]}],\"variableName\":\"sans\"}")
import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import type { TextImageStoryblok } from "@/component-types-sb";
import { BodyText, H3 } from "@/components/typography";

type TextImageProps = {
  blok: TextImageStoryblok;
};

const TextImage = ({ blok }: TextImageProps) => (
  <div className="text-left mb-4" {...storyblokEditable(blok)}>
    <Image
      className="object-cover w-full h-48"
      src={blok.image?.filename || ""}
      width={400}
      height={300}
      alt={blok.image?.alt || blok.title || ""}
    />
    <H3 colorScheme="primary">{blok.headline}</H3>
    <BodyText className="max-w-2xl mx-auto mt-4">{blok.text}</BodyText>
  </div>
);

export default TextImage;
